

.BG{
  width: 100%;
  position: absolute;
  z-index: -1;

}
.Main{
  position: relative;
  display: flex;

  justify-content: end;
  height: 800px;

}
.panel{

  right: 5%;
 
  height: 90vh;
  width: 390px;
  margin: 25px;
  border-radius: .5rem;

  align-items: center;
  justify-content: center;
  
}
.panelframe{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 95%;
  

}
.Footer{
  background-color: black;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.Footerframe{
  padding: 2.5rem;
}

.desc{
  display: flex;
  flex-direction: column;
  gap: 2.5rem;
}
.desctittle{
  color: #da004c;
  font-size: 1.125rem;
  line-height: 1.75rem;
}
.desctext{
  color: white;
  font-size: 1rem;
  line-height: 1.5rem;
}
.contactittle{
  font-size: 1.25rem;
        line-height: 1.75rem;
        font-weight: 700;
}
.contact{
  --tw-text-opacity: 1;
    color: rgb(59 130 246 / var(--tw-text-opacity));
    display: flex;
    flex-direction: column;
    gap: .5rem;
}
.flexcontact{
  display: flex;
  gap: .5rem;
  font-size: 14px;
}
.services{
  gap: 15px;
  display: flex;
  flex-direction: column;
 
}
.services img{
  width: 16rem;
  height: 4rem;
}
.service{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 98%;
  width: 100%;
  align-items: center;
  background-color: black;
  border-radius: 15px;
  padding-bottom: 5px;
  
}
.serviceframe{
  height: 85%;
  width: 96%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow-y: scroll;


}

.serviceframe::-webkit-scrollbar{
  display: none;
}
.serviceframe h1{
  --tw-text-opacity: 1;
    color: rgb(34 197 94 / var(--tw-text-opacity));
    font-size: 20px;
    text-align: center;
}
.questions{
  width: 95%;
  overflow: hidden;
  overflow-y: scroll;

  height: 90%;
  display: flex;
  flex-direction: column;
}
.questions::-webkit-scrollbar{
  display: none;
}
.question{
  width: 100%;
  border: 1px solid rgb(238, 238, 238);
  padding: 10px;
  background-color: rgb(244, 244, 244);
}
.question button{

  border: 0;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
}
.question li{
  font-size: 16px;
}
.question ul{
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.questiontittle{
  font-size: 18px;
  --tw-text-opacity: 1;
  color: rgb(239 68 68 / var(--tw-text-opacity));
  text-align: start;
  background: none;


  color: black;
  width: 95%;

}
.calendar{
  width: 100%;
  color: white;
}
.calendar input{
  width: 75%;
  padding: 10px;
}
.calendar select{
  padding: 10px;
  margin-top: 5px;
}
.calendar{
  display: flex;
  align-items: center;
 
  gap: 15px;
}
.Answer{
  color: black;
  padding: 5px;
  text-align: start;
  width: 90%;
}
.tire{
  width: 100%;
}

.button1{
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
  width: 390px;
  border: 0;
  padding: 10px;
  color: white;
  cursor: pointer;
}
.autocall{
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
  width: 99%;
  border: 0;
  padding: 10px;
  text-align: center;
  color: white;
  cursor: pointer;
  margin-top: 5px; 
  

  text-decoration: none;
}
.answerimage{
  width: 94%;

}
.notif{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: fixed;

}
.notifframe{
  width: 400px;
  height: 300px;
  background-color: white;

  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  padding: 10px;
  gap: 35px;
  border-radius: 15px;

}
.notiflogo{
  width: 100px;
  height: 100px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #14b8a6;
}
.notiflogo img{
  width: 70px;
  height: 70px;
}

.notiftitlte{
  text-align: center;
}
.notifframe button{
  width: 150px;
  padding: 10px;
  background-color: #14b8a6;
  border: 0px;
  outline: 0px;
  color: white;
  border-radius: 5px;
  cursor: pointer;
}

.homepanel{
 display: flex;
  height: 800px;
  width: 400px;
  margin: 25px;
  border-radius: .5rem;
  background-color: black;
  align-items: center;
  justify-content: center;
}
.homepanelframe{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 95%;

  
}
.titldesc{
  width: 100%;

  font-style: italic;
  color: white;
  text-align: center;
  font-weight: 700;
  margin-bottom: 5px;


}
.links{
  --tw-text-opacity: 1;
  color: rgb(96 165 250 / var(--tw-text-opacity));
}

.map{
  display: flex;
  flex-direction: column;
  text-align: center;
  color: white;

}

.mapservice{
  display: flex;
  flex-direction: column;

  height: 98%;
  width: 100%;
  align-items: center;
  background-color: black;
  border-radius: 15px;
}

.mapframe{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 85%;
  margin-top: 30px;
  width: 90%;
  position: relative;
  
}
.locationframe{
  position: absolute;

  z-index: 2;
  top: 80%;
  display: flex;
  flex-direction: column;
  background-color: white;
  padding: 10px;
  width: 260px;
  justify-content: space-between;
  height: 100px;

  --tw-text-opacity: 1;
  color: rgb(59 130 246 / var(--tw-text-opacity));
  text-align: center;
}
.locationframe button{
  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
  border: 0;
  padding: 16px;
  color: white;
  font-size: 20px;
  cursor: pointer;
}
.map{
  display: flex;


  height: 93%;
  margin-top: 30px;
  width: 100%;
  position: relative;
  
}
.regiontittle{
  color: white;
  text-align: center;
  margin-bottom: 10px;
  font-size: 20px;
  font-weight: 700;
  
}
.regions{
  width: 100%;
}
.chooselist{
  display: flex;
  align-items: center;
  justify-content: center;
}
.chooselist button{
  background: none
  ;
  border: 0;
  height: 112px;
  cursor: pointer;
}
.choosetittle{
  text-align: center;
  color: white;
  font-size: 20px;
  font-weight: 700;
  margin-bottom: 15px;
}
.selectlist select{
  background-color: black;
  color: white;
}
.bandemat{

  background-color: white;
  padding: 10px;
}
.bandemat{
  text-align: center;
}
.selectlist{
  gap: 5px;
  display: flex;
  margin-top: 15px;
}
.selectlist select{
  width: 19%;
}
.checkbox{
  display: flex;
  align-items: center;
  font-size: 15px;
  text-align: start;  
}
.checkbox input{
  width: 20px;
  height: 20px;
}
.price{
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
}
.totalprice{
  text-align: center;
  color: white;
  font-weight: 700;
  font-size: 24px;
}
.price p {
  text-align: center;
  color: white;
  margin-top: 5px;
}
.bandemat{
  margin-top: 15px;
}
.orderbutton{
  padding-top: 25px;
  bottom: 1%;
  left: -8px;
  padding: 15px;
  width: 100%;

  --tw-bg-opacity: 1;
  background-color: rgb(34 197 94 / var(--tw-bg-opacity));
  color: white;
  font-weight: 700;
  border: 0;
  cursor: pointer;
}
.notchoosedbtn{
  background: none;
  border: 0;
}
.regionbtn img{
  width: 100px;
  height: 100px;
}
.choosedbutton{
  background-color: rgb(51, 51, 51);
  border: 0;
}
.regionbtn{
  display: flex;
  justify-content: space-between;
}
.regionbtn button{
  width: 33.3%;
  cursor: pointer;
}
.choosedchooser{
  border: 1px solid green !important;
  padding: 0;
  margin: 0;


}
.Map{
  width: 100%;
  height: 100vh;
}
.phone{
  display: flex;
  align-items: center;

}
.country{
  max-width: 20%;
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0px !important;
  margin: 0;
}
.country div{
  padding: 0px;
}
.phoneinput{
  width: 100%;
  
  border: 0;
  outline: 0;
border-radius: 10px;
  height: 37px;
}
.phonevalues{
border-radius: 10px;
  display: flex;
  align-items: center;
  background-color: white;
  width: 100%;
}

.label{
  padding-bottom: 10px;
  padding-top: 10px;
  color: white;
}
.formval{
  display: flex;
  flex-direction: column;
  width: 90%;
}
.email{
  padding: 10px;
  border-radius: 10px;

}
.vorwarden{
  text-decoration: underline;
  text-align: start !important;

  color: white !important;
}

.terms{
  color: white  ;
  padding: 30px;
  
}
.Whatsappsubmit{
  padding: 10px;
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity));
  border: 0;
  border-radius: 5px;
  color: white;
}
.Emailsubmit{
  margin-top: 10px;
  padding: 10px;
  --tw-bg-opacity: 1;
  background-color: rgb(74 222 128 / var(--tw-bg-opacity));
  border: 0;
  border-radius: 5px;
  color: white;
}
.bericht{
  height: 100px;
  border-radius: 10px;
}
.minus{
  color: red;

  width: 20px;
}
.plus{

  width: 20px;
}
.Vernden{
  padding: 15px;
  --tw-bg-opacity: 1;
  background-color: rgb(239 68 68 / var(--tw-bg-opacity));
  --tw-text-opacity: 1;
  color: rgb(255 255 255 / var(--tw-text-opacity));
  border-radius: .375rem;
  border: 0;
  margin-bottom: 10px;
}
.orderframe{
  width: 100%;
  display: flex;
  justify-content: center;
}
.bandeemchoose{
  width: 100%;
  --tw-bg-opacity: 1;
  background-color: rgb(17 24 39 / var(--tw-bg-opacity));
  padding: 5px;
}
.bandemcheckbox{
  display: flex;
  justify-content: space-between;
  color: white;
  text-transform: capitalize !important;

}
.bandemcheckbox input{
  width: 1.25rem;
  height: 1.25rem;
}
.bandenmaat{
  display: flex;
  align-items: center;
  color: white;
  gap: 5px;
}
.selectlistband{
  width: 100%;
}
.selectlistband select{
  width: 19.5%;
}
.bandenmerk{
  margin-top: 7px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-weight: 600;
}
.bandeemchoose select{
  background-color: black;
  color: white;
}
.bandenmerk select{
  width: 50%;
}
.TypeBand{
  margin-top: 10px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  color: white;
  font-weight: 600;
}
.TypeBand select{
  width: 50%;
}
.watsappmenu{
  position: fixed;
  display: flex;
  width: 100%;
  height: 100vh;
  background-color: rgba(151, 151, 151, 0.23);

  display: flex;
  align-items: center;
  justify-content: center;
  left: 0;
  top: 0;

}
.watframe{
  background-color: white;
  padding: 20px;
  width: 400px;
  height: 300px;
  color: black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  text-align: center;
}
.watmenutittle{
  font-size: 24px;
  font-weight: 700;
}
.watbtns{

  display: flex;
  align-items: center;
  gap: 15px;
}
.watbtns button{
  padding: 15px;
  cursor: pointer;


}
.yesbtn{
  background-color: #3b82f6;
  border: 0;
  width: 80px;
  color: white;
  border-radius: 3px;
}
.cancelbtn{
  background-color:#ef4444;
  border: 0;
  width: 80px;
  color: white;
  border-radius: 3px;


}
.error{
  color: red;
  padding: 5px;
}

.Answer{
  width: 90%;
}
.interventie{
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;
  margin-top: 5px;
}

.navigatorinfo{
  width: 300px;
  height: 300px;
  position: fixed;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
  display: flex;
  left: 0px;
  top: 0px;
  z-index: 5;
}
.navigator{
  padding: 20px;
  z-index: 22;
  width: 300px;
  height: 300px;
  display: flex;
  background-color: white;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: black;
  gap: 25px;
}
.btns{
  display: flex;
  align-items: center;
  gap: 15px;
}
.btns button{
  padding: 10px;
}
.btns :nth-child(1){
  background-color:#10b981;
  border: 0;
  outline: 0;
  color: white;
}
.btns :nth-child(2){
  background-color: #ef4444;
  border: 0;
  outline: 0;
  color: white;

}
body{
  background-color: black;
}
.Vernden{
  cursor: pointer;
  transition: 0.1;
}
.Vernden:active{
  background-color: #da004ca4;
}
.homepanelframe{
  align-items: center;
}
.services{
  align-items: center;
}
.panel{
  display: flex;
  align-items: center;
}
.question{
  align-items: center;
  display: flex;
  flex-direction: column;
}

.titldesc{
  width: 90%;
}
.noti{
  color: white;
}

@media (max-height:550px) {
  .locationframe{
    top: 75% !important;
  }
  
}
@media (max-height:475px) {
  .locationframe{
    top: 70% !important;
  }
  
}
@media (max-height:475px) {
  .locationframe{
    top: 70% !important;
    width: 240px !important;

  }
 
  
}

@media (max-width:450px) {
  .bandenmaat{
    flex-direction: column;
    align-items: start;
  }

  
  .Main{
    justify-content: center;

    margin: 0px;
    height: auto;
    width: 100%;
  }
  .panel{
    width: 100%;
  }
  .Footer{
    width: 400px;
  }
  .button1{
    width: 100%;
  }
  .autocall{
    width: 100%;
    padding: 0;
    padding-top: 5px;
    padding-bottom: 5px;
    text-align: center;
  }


  
}
@media (max-width:400px) {

  .chooselist img{
    width: 100px;

  }
  .locationframe{

    width: 230px;
  }
  .orderbutton{
    width: 100%;
  }

}
@media (max-width:750px) {
  
  .BG{
    display: none;
  }

  .Main{
    justify-content: center;
  }
  
}